.content-wrapper a {
  @apply text-blue-500;
}
.content-wrapper p {
  @apply mb-4;
}
.content-wrapper strong {
  @apply font-bold;
}
.content-wrapper ul {
  @apply ml-6;
}
.content-wrapper li {
  @apply list-disc font-bold pl-4;
}
.content-wrapper span {
  @apply font-normal italic;
}
.content-wrapper ol {
  @apply ml-6 mt-6 list-image-[url(/bullet.png)];
}
.content-wrapper ol li {
  @apply mb-[0.5rem] md:mb-[1rem] list-disc font-bold pl-4 text-[0.75rem] md:text-[1rem];
}
