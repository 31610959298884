/* !important is needed sometimes */
.scrollBars::-webkit-scrollbar {
  width: 10px !important;
  height: 2px;
}

/* Track */
.scrollBars::-webkit-scrollbar-track {
  -webkit-border-radius: 20px !important;
  border-radius: 20px !important;
  background-color: rgba(30, 30, 30, 1);
  height: 5px;
}

/* Handle */
.scrollBars::-webkit-scrollbar-thumb {
  -webkit-border-radius: 20px !important;
  border-radius: 20px !important;
  background: #fed101 !important;
  height: 5px;
}
.scrollBars::-webkit-scrollbar-thumb:window-inactive {
  background: #fed101 !important;
}
