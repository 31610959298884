.carousel ul {
  right: initial;
}
.carousel ul li button {
  width: 18px;
  height: 18px;
  border: 1px solid #6b6b6b;
  margin-right: 15px;
}
.carousel ul li[class$="active"] button {
  background-color: #fed101 !important;
}
