.carousel ul li button {
  width: 13px;
  height: 13px;
  border: 1px solid #c8c8c8;
  margin-right: 13px;
}
.carousel ul li[class$="active"] button {
  background-color: #fed101 !important;
}

@media screen and (max-width: 1023px) {
  .carousel {
    padding: 0 40px;
  }
}
